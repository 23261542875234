._bg-1 {
    background-image: url('../../../public/img/bg.png');
}

.anim1 {
    animation: float 5s ease-in-out infinite;
}

@keyframes float {
    0% {
        transform: translatey(0px);
    }

    50% {
        transform: translatey(-20px);
    }

    100% {
        transform: translatey(0px);
    }
}